import React from "react";

import BoxText2Image1ImageHideSm from "../../Shared/BoxText2Image1ImageHideSm";

import siemensLogo from "../../../assets/images/siemens.png";

export default () => {
  return (
	<BoxText2Image1ImageHideSm 
		img={siemensLogo}
		title='
<span class="text-xl kc-color-2">Siemens&nbsp;</span><span class="text-lg">&mdash; Automation Service Specialist</span>'
		body='
<ul class="list-disc ml-7">
	<li>Troubleshoot programming and networking issues in building automation systems. </li>
	<li>Took the initiative to be one of two people in the Chicago branch certified in the Siemens Enlighted system.</li>
	<li>Diagnosed and repaired electronic and pneumatic control system malfunctions.</li>
	<li>Commissioning and general servicing of servers, front-ends and HVAC related equipment.</li>
	<li>Mentored mechanical technicians on building automation activities and proprietary software.</li>
</ul>'
	/>
  )
}