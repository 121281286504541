import React, {Component} from "react";
import ReactDOM from 'react-dom';
import './Styles/Timeline.css';
import noteToSelf from "../../Shared/noteToSelf.js";
import Scrubber from './Scripts/Scrubber.js';
import Technologies from './Scripts/Technologies.js';
import background from './Images/timeline.jpg';

// 1982-1986, 1987-1993, 1994 - present
class Timeline extends Component {
    constructor(props) {
        super(props);
        const myDate = new Date();
        const myYear = (Number(myDate.getFullYear())<2022 ? 2022: Number(myDate.getFullYear()) );
        this.state = {
            yearNow: myYear,
            yearSelected: myYear,
            isMouseDown: false,
            x:0,
            message: "start"
        };
    }
    getData(myPosition){
        myPosition = myPosition>1 ? 1 : myPosition;
        this.setState({ yearSelected: (Math.ceil(1984 + ((this.state.yearNow-1984)*myPosition))) });
    };
    scrubHorizontal(){
        const timelineGraphic = document.getElementById("timeline-graphic");
        const timelineScrubber = document.getElementById("timeline-scrubber");
        if(timelineGraphic.offsetHeight>0){
            timelineScrubber.style.top = (((timelineGraphic.offsetHeight - timelineScrubber.offsetHeight)/2)+timelineGraphic.offsetTop)+"px";
        }
    }
    setX(e){
        const timelineGraphic = document.getElementById("timeline-graphic");
        const timelineScrubber = document.getElementById("timeline-scrubber");
        let timelineWidth = timelineGraphic.offsetWidth;
        let timelineLeft = (e.pageX-timelineGraphic.offsetLeft<0 ? timelineGraphic.offsetLeft : e.pageX-((timelineScrubber.offsetWidth)/2));
        timelineLeft = (timelineLeft>timelineWidth+timelineGraphic.offsetLeft ? timelineWidth+timelineGraphic.offsetLeft-timelineScrubber.offsetWidth : timelineLeft);
        if(timelineLeft<0){
            timelineLeft=0;
            this.setState({ isMouseDown: false});
        } else if(timelineLeft>timelineWidth+timelineGraphic.offsetLeft){
            timelineLeft = timelineWidth-timelineGraphic.offsetLeft;
            this.setState({ isMouseDown: false});
        }

        timelineScrubber.style.left = timelineLeft+"px";
        this.setState({ x: timelineLeft });
        this.getData((timelineLeft-timelineGraphic.offsetLeft)/(timelineWidth-timelineScrubber.offsetWidth));   
    }
    _onMouseDown(e) {
        this.scrubHorizontal();
        this.setState({ isMouseDown: true});
        this.setX(e);
    };
    _onMouseUp(e) {
        this.setState({ isMouseDown: false});
    };
    _onMouseMove(e) {
        if(this.state.isMouseDown){
            this.setX(e);
        }
    }
    _onMouseOut(e) {
        const timelineGraphic = document.getElementById("timeline-graphic");
        if(e.pageX<timelineGraphic.offsetLeft){
            this.setState({ isMouseDown: false});
        } else {
            if (e.pageX > timelineGraphic.offsetWidth + timelineGraphic.offsetLeft) {
                this.setState({isMouseDown: false});
            } else {
                if (e.pageY < timelineGraphic.offsetTop) {
                    this.setState({isMouseDown: false});
                } else {
                    if (e.pageY > timelineGraphic.offsetHeight + timelineGraphic.offsetTop) {
                        this.setState({isMouseDown: false});
                    }
                }
            }
        }
    };
    componentDidMount() {
        // use isReady()
    };
    isReady() {
        const timelineGraphic = document.getElementById("timeline-graphic");
        const timelineScrubber = document.getElementById("timeline-scrubber");
        timelineScrubber.style.left = (timelineGraphic.offsetWidth-15+timelineGraphic.offsetLeft)+"px";
        timelineScrubber.style.display = "block";
        this.scrubHorizontal();
    };
    render() {
        return (
            <div className="p-3">
                <div>
                    < Technologies yearNow={this.state.yearNow} yearSelected={this.state.yearSelected} />
                </div>
                <div id="timeline-hotspot"
                onMouseDown={this._onMouseDown.bind(this)}
                onMouseUp={this._onMouseUp.bind(this)}
                onMouseMove={this._onMouseMove.bind(this)}
                onMouseOut={this._onMouseOut.bind(this)}
                >
                    < Scrubber date={this.state.yearSelected} onMouseOut={this._onMouseOut.bind(this)} />
                    <img id="timeline-graphic" src={background} alt="Timeline" onLoad={() => this.isReady()}/>
                </div>
            </div>
        );
    }
}

export default Timeline;

if (document.getElementById('timeline')) {
    ReactDOM.render(<Timeline />, document.getElementById('timeline'));
}
