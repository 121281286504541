import React from "react";

import BoxOutlined from "../../Shared/BoxOutlined";

import fortnite from "../../../assets/images/fortnite.png";

export default () => {
  return (
	<BoxOutlined 
		title='Fortnite'
		body='Playing online games with co-workers once a week (after hours of course).'
		img={fortnite}
		buttonText='Fortnite Stats'
		buttonUrl='https://fortnitetracker.com/profile/all/KillinCarr'
	/>
  )
}