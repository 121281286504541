import React from 'react';
import IconButton from "../../Shared/IconButton";

import emailIcon from "../../../assets/images/icon-email.svg";
import linkedInIcon from "../../../assets/images/icon-linkedin.svg";
import gitHubIcon from "../../../assets/images/icon-github.svg";
import imdbIcon from "../../../assets/images/icon-imdb.svg";

const Footer = () => {

    return <div>
        <div className="flex flex-wrap justify-center">
            <IconButton 
                img={emailIcon}
                buttonUrl='mailto:himself@kevincarr.com'
            />
            <IconButton 
                img={linkedInIcon}
                buttonUrl='https://www.linkedin.com/in/kevin-carr-7398576/'
            />
            <IconButton 
                img={gitHubIcon}
                buttonUrl='https://github.com/kevincarr'
            />
            <IconButton 
                img={imdbIcon}
                buttonUrl='https://www.imdb.com/name/nm5250638/'
            />
        </div>
    </div>
}

export default Footer;