import React from "react";
import styled from 'styled-components';

export default ({buttonUrl, img}) => {

    const MaskIcon = styled.div`
        -webkit-mask-image: url( ${img});
        mask-image: url(${img});
        -webkit-mask-size: 30px;
        mask-size: 30px;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat; 
        width:30px;
        height:30px;
        `;
  return (
    <div className="flex flex-wrap py-5 px-3 pt-12">
        <a href={buttonUrl} target="_blank">
            <MaskIcon className="kc-btn-color"></MaskIcon>
        </a>
    </div>
  );
};
