import React from "react";
import styled from 'styled-components';

const ButtonStyle = styled.div`
  width: 75%;
  text-align: center;
  margin: auto;
  align-items:center;
  font-weight: 500;
  cursor: pointer;
  text-decoration:none;
  color: #ffffff;
  background-color: #103d82;
  border-radius: 8px;
  padding: 5px;
  transition: 0.2s;
  border: 2px solid #103d82;
:hover{
  background-color: #7ea2d1;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}`;

export default ({link, text}) => {
  return (
          <a href={link} target="_blank">
            <ButtonStyle className="text-xl">
            
              {text}&nbsp;<svg className="inline" width="18" height="16" fill="none">
                <path fill="none" stroke="currentColor" d="M 0,8 l 16,0 -6,6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                <path fill="none" stroke="currentColor" d="M 16,8 l -6,-6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </ButtonStyle>
          </a>
  )
}