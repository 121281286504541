import React from "react";

import styled from 'styled-components';

const HorizontalLine = styled.div`
  height: 2px;
  background-color:#103d82;
`;

export default () => {
  return (
    <div className="bg-white py-5 px-2.5">
      <HorizontalLine>
      </HorizontalLine>
    </div>
  )
}