import React from 'react';
import technologies from "./technologies.json";
import Technology from './Technology.js';


const Technologies = (props) => {
    let listNew = [];
    technologies.Technologies.map((technology) => {
        let myLevel;
        let myBefore = 0;
        let myAfter = technology.years.length-1;
        for (let i = 0; i < technology.levels.length; i++) {
            if(technology.years.length>i){
                if(technology.years[i]>=props.yearSelected){
                    myBefore = i;
                }
                if(technology.years[technology.years.length-i-1]<=props.yearSelected){
                    myAfter = technology.years.length-i-1;
                }
            }
        }
        /* // Figure out the tweening later
        if(myBefore==myAfter){
            myLevel = technology.levels[myBefore]*.1;
        } else{
            myLevel = (technology.levels[myBefore]*(technology.years[myBefore]-props.yearSelected)/(technology.years[myBefore]-technology.years[myAfter]))*.1;
        }
        */
        myLevel = technology.levels[myBefore]*.1;
        listNew.push({
            name: technology.name,
            level: myLevel
        });
    });

    let dateHTML= String(props.yearSelected).split("").join('<br/>');
    //let dateHTML= props.date;
    return (
        <div className="flex flex-wrap justify-content-center">
            {
                listNew.map((technology, index) => {
                    return (
                        <Technology
                            key={index}
                            name={technology.name}
                            level={technology.level}
                        />
                    );
                })
            }
        </div>
    );
};
export default Technologies;
