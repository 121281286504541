import React from 'react';

const Technology = (props) => {
    const newLevel = String((props.level>0 ? props.level : ".1"));
    const classes = (props.level>0 ? "time-capsule kc-color-btn-r text-white mr-1 mt-1" : "time-capsule tech-inactive text-white mr-1 mt-1");
    const timeCapsuleInner = {
        fontSize: newLevel+"rem",
    };
    const timeCapsuleContainer = {
        height: "45px",
        overflow: "hidden"
    };
    return (
        <div style={timeCapsuleContainer}>
            <div className="text-center">
                <span className={classes} style={timeCapsuleInner}>
                    &nbsp;{props.name}&nbsp;
                </span>
            </div>
            <div className="timeline-placeholder mr-1 mt-1">
                &nbsp;{props.name}&nbsp;
            </div>
        </div>
    );
};
export default Technology;
