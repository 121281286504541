import React from "react";

import styled from 'styled-components';

export default ({title, body, img}) => {
  return (
            <div className="flex flex-wrap py-5">
              <div className="px-2.5 w-full md:w-3/4">

                <div className="px-2.5" dangerouslySetInnerHTML={{ __html: title }}>
                </div>
                <div className="px-2.5" dangerouslySetInnerHTML={{ __html: body }}>
                </div>
              </div>
              <div className="px-2.5 w-full md:w-1/4 hidden md:table-cell">
                <img className="image" src={img} alt="" />
              </div>
            </div>
  )
}