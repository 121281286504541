import React from "react";

import BoxImage1Text2ImageHideSm from "../../Shared/BoxImage1Text2ImageHideSm";

import brellaLogo from "../../../assets/images/brella.png";

export default () => {
  return (
	<BoxImage1Text2ImageHideSm 
		img={brellaLogo}
		title='
<span class="text-xl kc-color-2">Brella Productions&nbsp;</span><span class="text-lg">&mdash; Senior Programmer</span>'
		body='
<ul class="list-disc ml-7">
	<li>Programmed applications for a fortune 500 pharmaceutical company that worked with a video conferencing platform to seamlessly display sensitive content on a secure server.</li>
	<li>Developed a stable framework for a learning management system that became the company’s standard.</li>
	<li>Converted proprietary stand-alone training executables into SCORM compliant web based applications.</li>
	<li>Promoted a culture of confidence for our clients by building durable applications that could be modified easily and required minimum maintenance.</li>
	<li>Interpreted the needs of the client or project manager considering cost, technical and timeline limitations.</li>
	<li>Estimated projects and document goals to ensure effectiveness and efficiency.</li>
	<li>Encouraged consistent programming and accelerated task completion with a strict code review process.</li>
	<li>Oversaw the training of new members to ensure confidence and a standardized workflow.</li>
</ul>'
	/>
  )
}