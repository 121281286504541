import React from "react";

import BoxOutlined from "../../Shared/BoxOutlined";

import mordyToTheMax from "../../../assets/images/mordy-to-the-max.png";

export default () => {
  return (
	<BoxOutlined 
		title='Mordy to the Max'
		body='Had a small part in a movie written, filmed and edited by co-workers. This was not a company sanctioned project.'
		img={mordyToTheMax}
		buttonText='IMDB'
		buttonUrl='https://www.imdb.com/title/tt2369666/characters/nm5250638'
	/>
  )
}