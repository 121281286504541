import React from "react";

export default () => {
  return (
								<div className="h-full bg-gray-200 px-2.5">
									<div className="px-2.5 text-2xl kc-color-2">
Knowledge
									</div>
									<div className="px-2.5 text-xl kc-color-2">
Languages
									</div>
									<div className="flex flex-row pb-3">
									  <div className="w-1/2">
<ul className="list-disc ml-7">
	<li>HTML</li>
	<li>JavaScript</li>
	<li>CSS</li>
	<li>XML</li>
	<li>JSON</li>
	<li>PHP</li>
</ul>
									  </div>
									  <div className="w-1/2">
<ul className="list-disc ml-7">
	<li>MySQL</li>
	<li>Java(Android)</li>
	<li>AS3</li>
	<li>PPCL</li>
	<li>GDScript</li>
</ul>
									  </div>
									</div>

									<div className="px-2.5 text-xl kc-color-2">
Frameworks/Libraries
									</div>



									<div className="flex flex-row pb-3">
									  <div className="w-1/2">
<ul className="list-disc ml-7">
	<li>Laravel</li>
	<li>React</li>
	<li>Tailwind</li>
	<li>jQuery</li>
</ul>
									  </div>
									  <div className="w-1/2">
<ul className="list-disc ml-7">
	<li>Bootstrap</li>
	<li>SCORM</li>
	<li>Node Express</li>
</ul>
									  </div>
									</div>


									<div className="px-2.5 text-xl kc-color-2">
Software
									</div>


									<div className="flex flex-row pb-3">
									  <div className="w-1/2">
<ul className="list-disc ml-7">
	<li>Git</li>
	<li>GitHub</li>
	<li>GitLab</li>
	<li>PhpStorm</li>
	<li>Terminal</li>
</ul>
									  </div>
									  <div className="w-1/2">
<ul className="list-disc ml-7">
	<li>Godot</li>
	<li>Photoshop</li>
	<li>Illustrator</li>
	<li>InDesign</li>
	<li>After Effects</li>
</ul>
									  </div>
									</div>
								</div>
  )
}