import React from 'react';


const Scrubber = (props) => {
    let dateHTML= String(props.date).split("").join('<br/>');
    //let dateHTML= props.date;
    return (
        <div id="timeline-scrubber" className="scrubber text-white kc-color-btn-n text-center select-none cursor-pointer" dangerouslySetInnerHTML={{__html: dateHTML}}>
        </div>
    );
};
export default Scrubber;
