import React from "react";

import BoxOutlined from "../../Shared/BoxOutlined";

import muckFest from "../../../assets/images/muckfest.png";

export default () => {
  return (
          <BoxOutlined 
            title='MuckFest'
            body='Ran on a company sponsored team in an obstacle course race that raised money for the National Multiple Sclerosis Society.'
            img={muckFest}
            youtubeID='Kg7ukghNUOw'
          />
  )
}