import React, { useState } from "react";

import styled from 'styled-components';

import VideoN from "../../../assets/images/video-n.png";
import VideoR from "../../../assets/images/video-r.png";
import VideoB from "../../../assets/images/video-b.png";

import "./Modal.css";

const VideoRollover = styled.div`
  background-image: url(${VideoN});
  background-size: contain;
  transition: .5s;
  &: hover {
    background-image:url(${VideoR});
    transition: .5s;
  }
`;

const VideoIFrame = styled.div`
  width: ${props => props.myWidth || 560};
  height: ${props => props.myHeight || 315};
`;

export default function VideoModal({title, img, buttonUrl, youtubeID}) {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  let myWidth=(window.innerWidth*.5)-10;
  let myHeight=((window.innerHeight*.45)-10)-25;// -25 for the title bar


  return (
    <>
      <div onClick={toggleModal} className="w-full kc-btn image" style={{backgroundImage: `url(${img})`}} >
          <VideoRollover className="my-2">
            <img className="image" src={VideoB} alt="Play Video" />
          </VideoRollover>
      </div>

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content bg-black text-slate-300">
            <div className="text-center">
              <button className="close-modal leading-5" onClick={toggleModal}>
                &times;
              </button>
            </div>
            <div className="clear-both">
<iframe allowfullscreen="" width={myWidth} height={myHeight} src={"https://www.youtube.com/embed/"+youtubeID} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}