import React, {Component} from "react";
import ReactDOM from 'react-dom';
import noteToSelf from "../../Shared/noteToSelf.js";
import HorizontalLine from "../../Shared/HorizontalLine";
import ButtonSolid from "../../Shared/ButtonSolid";
import TimeLine from "../../Shared/Timeline/Timeline";

import Delaval from "./Delaval";
import Siemens from "./Siemens";
import Brella from "./Brella";

import Knowledge from "./Knowledge";

import MuckFest from "./MuckFest";
import MordyToTheMax from "./MordyToTheMax";
import Fortnite from "./Fortnite";

import Footer from "./Footer";

import titleImage from "../../../assets/images/title-image.png";

class Home extends Component {
    render() {
        return (
			<div className="container">
				<img className="image" src={titleImage} alt="title" />
				<div className="bg-white">
					<div className="py-2.5 text-center text-3xl kc-color-2">
Kevin Carr
					</div>
					<div className="text-lg text-center">
Over two decades of experience in translating the needs of clients and developing them into practical web applications.
					</div>
					<HorizontalLine/>
					<div className="flex flex-wrap">
						<div className="w-full md:w-2/3 pb-3">
							<div className="px-2.5 text-2xl kc-color-2">
Experience
							</div>
							<Delaval />
							<Siemens />
							<Brella />

							<ButtonSolid
							link="https://brella.com" 
							text="Brella Production"
							/>
						</div>
						<div className="px-2.5 w-full md:w-1/3">
							<Knowledge />
						</div>
					</div>
					<div className="px-2.5 py-5">
						<div className="text-2xl kc-color-2">
Progression of Programming Skills
						</div>
						<p>
An interactive timeline displaying the year and level of understanding with each technology.
						</p>
					</div>
					<TimeLine />
					<div className="p-2.5 text-2xl kc-color-2">
Team-Building Activities
					</div>
					<div className="flex flex-wrap">
						<MordyToTheMax />
						<MuckFest />
						<Fortnite />
					</div>
					<Footer />
				</div>
			</div>
        );
    }
}

export default Home;

if (document.getElementById('home')) {
    ReactDOM.render(<Home />, document.getElementById('home'));
}
