import React from "react";

import BoxImage1Text2ImageHideSm from "../../Shared/BoxImage1Text2ImageHideSm";

import delavalLogo from "../../../assets/images/delaval.jpeg";

export default () => {
  return (
	<BoxImage1Text2ImageHideSm 
		img={delavalLogo}
		title='
<span class="text-xl kc-color-2">DeLaval&nbsp;</span><span class="text-lg">&mdash; Freelance Web Developer</span>'
		body='
<ul class="list-disc ml-7">
	<li>Designed an internal website using maintainable code for rapid updates and modifications.</li>
	<li>Migrated content from several different websites into a central content management system.</li>
	<li>Created simplified templates to allow developers with any level of experience to create pages that are consistent with corporate standards.</li>
	<li>Served as a subject matter expert and advised stakeholders on website technologies and implementation.</li>
</ul>'
	/>
  )
}