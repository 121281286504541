import logo from './logo.svg';
import './App.css';

import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Components/Pages/Home/Home";

function App() {
  return (
        <BrowserRouter>
      <Routes>
        <Route strict exact path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
