import React from "react";

import ButtonSolid from "./ButtonSolid";
import VideoModal from './Modal/VideoModal'

export default ({title, body, img, buttonText, buttonUrl, youtubeID}) => {
  return (
    <div className="relative md:flex-1 p-2.5 m-2.5 border border-gray-200 rounded-md">
      <div className="text-xl kc-color-2 text-center">{title}</div>

        { youtubeID ?
          <VideoModal
            title={title}
            img={img} 
            buttonUrl={buttonUrl} 
            youtubeID={youtubeID} 
          /> 
          :
          <div className="px-2.5 w-full w-2/3 m-auto my-2">
            <img className="image" src={img} alt="" />
          </div>         
        }
      <div className="text-center my-2">{body}</div>
      <br/><br/>
      <div className="absolute bottom-0 w-full inline-block pb-3">
        { buttonText ?
          <ButtonSolid
            link={buttonUrl} 
            text={buttonText}
          />   
          : <div></div>          
        }
      </div>

    </div>
  );
};